import React from "react"
import "./index.css"
import { Card, Container, Row, Col } from "react-bootstrap"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import FirebaseApp from "./../../config/firebase"
import InputBase from "@material-ui/core/InputBase"
import SearchIcon from "@material-ui/icons/Search"
import Button from "@material-ui/core/Button"
import Qrimg from "./../../images/qrimg.png"
import { QRCode } from "react-qrcode-logo"

let database = FirebaseApp.database().ref("/")

export default class Certificates extends React.Component {
  constructor() {
    super()
    this.state = {
      certificate: null,
      certificateId: "",
      searchKey: "",
      name3: "",
    }
  }

  searchCertificate = () => {
    if (this.state.searchKey !== "") {
      database
        .child("PassesStudents/" + this.state.searchKey)
        .once("value", async res => {
          if (res.exists()) {
            var data = res.val()
            var data = res.key
            this.setState({ certificate: data })
            var qr = "https://portal.ihubar.com/CertificateID/" + data
            this.setState({ name3: qr })
          } else {
            this.setState({ certificate: "not found" })
          }
        })
    }
  }
  render() {
    return (
      <div className="_main_container">
        <AppBar position="static" className="_appBar">
          <Toolbar className="_toolBar">
            <Typography variant="h6">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/admission-portal-11282.appspot.com/o/ikillers-01.png?alt=media&token=aafafe1f-9990-46d9-97b3-68bfa64f5015"
                className="_Logo"
              />
            </Typography>
          </Toolbar>
        </AppBar>
        <Container fluid className="_result_view">
          <img
            src={require("../../images/certificate.png")}
            className="_certificate_icon"
          />
          <div
            style={{
              display: "flex",
              backgroundColor: "#eaeaea",
              borderRadius: 5,
              padding: 3,
              paddingLeft: 8,
              paddingRight: 8,
              boxShadow: "0px 0px 2px purple",
            }}
          >
            <div>
              <SearchIcon style={{ color: "purple" }} />
            </div>
            <InputBase
              placeholder="Enter Certificate ID"
              style={{ marginBottom: 0 }}
              inputProps={{ "aria-label": "search" }}
              onChange={e => this.setState({ searchKey: e.target.value })}
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ fontSize: 10, padding: 0 }}
              onClick={() => this.searchCertificate()}
            >
              Search
            </Button>
          </div>
          <br />
          <br />
          <br />
          {this.state.certificate === null ? null : this.state.certificate ===
            "not found" ? (
            <p style={{ fontSize: 22 }}>Certificate Not found</p>
          ) : (
            <QRCode
              logoImage={Qrimg}
              size={250}
              logoWidth={80}
              logoOpacity={1}
              value={this.state.name3}
            />
          )}
          {/* {this.state.certificate === "not found" && (
            <p style={{ fontSize: 22 }}>Certificate Not found</p>
          )}
          {this.state.certificate !== "not found" &&
            this.state.certificate !== null && (
              <>
                <h5>Certificate of Completion</h5>
                <p
                  style={{
                    fontSize: 20,
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  This is to certify that{" "}
                  <b>
                    {this.state.certificate.name} s/o{" "}
                    {this.state.certificate.fatherName}{" "}
                  </b>{" "}
                  successfully completed <b>{this.state.certificate.course}</b>{" "}
                  course <br /> on {this.state.certificate.PassedCourseDate}
                </p>
              </>
            )} */}
        </Container>
      </div>
    )
  }
}
